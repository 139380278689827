/**
 * @file RoomContext.tsx
 *
 * This file contains the implementation of a React context for managing a room connection
 * using Yjs and YPartyKitProvider. The primary goal is to handle the lifecycle of a room
 * connection within a React application, allowing components to access and interact with
 * the room's state and connection status.
 *
 * The context provides the following functionalities:
 * - Establishing a connection to the room when the associated component appears in the viewport.
 * - Disconnecting from the room when the component is no longer visible.
 *
 * This ensures that all components within this context can benefit from the collaborative
 * features provided by Yjs, optimizing resource usage by connecting only when necessary.
 *
 * Components and hooks provided in this file:
 * - RoomContext: The React context for room connection state.
 * - useRoom: A custom hook to manage and provide room connection state.
 * - RoomProvider: A context provider component that establishes and manages the room connection.
 * - useRoomContext: A custom hook to access the room context within the component tree.
 */

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from "react";
import YPartyKitProvider from "y-partykit/provider";
import * as Y from "yjs";

/**
 * @typedef {Object} RoomContextType
 * @property {YPartyKitProvider | null} provider - The Yjs provider instance
 * @property {boolean} isConnected - Connection status
 * @property {() => void} connect - Function to connect to the room
 * @property {() => void} disconnect - Function to disconnect from the room
 * @property {string} roomId - The room ID
 */
interface RoomContextType {
  provider: YPartyKitProvider | null;
  isConnected: boolean;
  connect: () => void;
  disconnect: () => void;
  roomId: string;
}

// Create a context with an initial undefined value
export const RoomContext = createContext<RoomContextType | undefined>(
  undefined
);

/**
 * Custom hook to manage room connection and state
 * @param {string} roomName - The name of the room to connect to
 * @returns {RoomContextType} The room context values
 */
export function useRoom(roomName: string): RoomContextType {
  const [isConnected, setIsConnected] = useState(false); // State to track connection status
  const [provider, setProvider] = useState<YPartyKitProvider | null>(null); // State to store the Yjs provider instance

  /**
   * Function to connect to the room
   */
  const connect = useCallback(() => {
    if (!isConnected) {
      const yDoc = new Y.Doc(); // Create a new Yjs document
      const yProvider = new YPartyKitProvider(
        window.location.host,
        roomName,
        yDoc,
        {
          connect: false,
        }
      );
      yProvider.connect(); // Establish connection
      setProvider(yProvider); // Set the provider state
      setIsConnected(true); // Update connection status
      return yProvider;
    }
  }, [isConnected, roomName]);

  /**
   * Function to disconnect from the room
   */
  const disconnect = useCallback(() => {
    if (isConnected && provider) {
      provider.destroy(); // Destroy the provider instance
      setProvider(null); // Reset the provider state
      setIsConnected(false); // Update connection status
    }
  }, [isConnected, provider, roomName]);

  /**
   * Cleanup function to disconnect when the component unmounts
   */
  useEffect(() => {
    return () => {
      if (isConnected && provider) {
        provider.disconnect();
      }
    };
  }, [isConnected, provider]);

  return {
    provider,
    isConnected,
    connect,
    disconnect,
    roomId: roomName,
  };
}

interface RoomProviderProps {
  roomId: string;
  children: ReactNode;
}

/**
 * RoomProvider component to provide room context to its children
 * @param {RoomProviderProps} props - The props for RoomProvider
 * @returns {JSX.Element} The rendered RoomProvider component
 */
export function RoomProvider({
  roomId,
  children,
}: RoomProviderProps): JSX.Element {
  const roomContext = useRoom(roomId); // Use the custom hook to manage room connection
  const ref = useRef<HTMLDivElement>(null); // Ref to track the visibility of the component

  /**
   * Intersection observer callback to handle connect and disconnect based on visibility
   */
  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !roomContext.isConnected) {
          roomContext.connect(); // Connect when the component is visible
        } else if (!entry.isIntersecting && roomContext.isConnected) {
          roomContext.disconnect(); // Disconnect when the component is not visible
        }
      });
    },
    [roomContext]
  );

  /**
   * Set up the intersection observer
   */
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (ref.current) {
      observer.observe(ref.current); // Observe the ref element
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current); // Clean up the observer
      }
    };
  }, [ref, handleIntersection]);

  return (
    <RoomContext.Provider value={roomContext}>
      <div ref={ref}>{children}</div>{" "}
      {/* Render children with the context provider */}
    </RoomContext.Provider>
  );
}

/**
 * Custom hook to access the RoomContext
 * @returns {RoomContextType} The room context values
 * @throws Will throw an error if used outside of RoomProvider
 */
export function useRoomContext(): RoomContextType {
  const context = useContext(RoomContext); // Access the context
  if (!context) {
    throw new Error("useRoomContext must be used within a RoomProvider"); // Error if context is not within a provider
  }
  return context;
}
