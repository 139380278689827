import QuillCursors from "quill-cursors";
import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillBinding } from "y-quill";
import { useRealTimeArray, useRealTimeText, useRoomContext } from "../../../client";

Quill.register("modules/cursors", QuillCursors);

interface Option {
  text: string;
  checked: number;
}

export function Card() {
  const [options, setOptions] = useRealTimeArray<Option[]>("options");
  const [name, setName] = useRealTimeText("name");
  const [text, setText] = useState("");
  const quill = useRef<ReactQuill>(null);
  const { provider } = useRoomContext()

  useEffect(() => {
    if (!provider) return;
    const editor = quill.current!.getEditor();
    const ytext = provider.doc.getText("quill");
    const binding = new QuillBinding(ytext, editor, provider.awareness);

    provider.awareness.setLocalStateField("user", {
      name: "Typing...",
    });

    return () => {
      binding.destroy();
    };
  }, [provider]);

  function addOptions() {
    setOptions([
      ...options,
      {
        text: name,
        checked: 0,
      },
    ]);
    setName("");
  }

  function checkOption(
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      checked: event.target.checked ? 1 : 0,
    };
    setOptions(newOptions);
  }

  function removeOption(index: number) {
    setOptions(options.filter((_, i) => i !== index));
  }

  return (
    <div
      className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <div className="mb-5">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Option Name
        </label>
        <input
          type="email"
          id="email"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
          placeholder="Enter the option name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />
      </div>
      <button
        onClick={addOptions}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Add Option
      </button>

      <div className="mt-5">
        {options.map((option, index) => (
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id={`checkbox-${index}`}
              checked={option.checked === 1}
              onChange={(event) => checkOption(event, index)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="checkbox-1"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex justify-center items-center"
            >
              <span>{option.text}</span>
              <span className="pl-5" onClick={() => removeOption(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-red-600 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </label>
          </div>
        ))}
      </div>

      <h1 className="mt-10 text-lg font-semibold text-gray-900 dark:text-white">
        Editor
      </h1>

      <div className="mt-5">
        <ReactQuill
          ref={quill}
          theme="snow"
          value={text}
          onChange={setText}
          modules={{ cursors: true }}
        />
      </div>
    </div>
  );
}
