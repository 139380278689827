import { useContext, useEffect, useState } from "react";
import { Text, Array as YArray } from "yjs";
import { RoomContext } from "./RoomContext";

/**
 * Type alias for Yjs data types
 */
type YDataType = Text | YArray<any>;

/**
 * Custom hook to manage real-time synchronization of Yjs data
 * @template T
 * @param {string} dataName - The name of the data in the Yjs document
 * @param {"text" | "array"} dataType - The type of the data ("text" or "array")
 * @returns {[T, (newData: T) => void]} The current data and a function to update it
 * @throws Will throw an error if used outside of RoomProvider
 */
export function useRealTime<T>(dataName: string, dataType: "text" | "array") {
  // Access the room context
  const roomContext = useContext(RoomContext);
  if (!roomContext) {
    throw new Error("useRealTime must be used within a RoomProvider");
  }
  const { provider } = roomContext;

  // Initialize state for the data
  const [data, setData] = useState<T>(() => {
    if (dataType === "text") {
      return "" as unknown as T;
    } else {
      return [] as unknown as T;
    }
  });

  // Effect to sync data with Yjs
  useEffect(() => {
    if (!provider) return;

    // Get the Yjs data instance
    const yData: YDataType =
      dataType === "text"
        ? provider.doc.getText(dataName)
        : provider.doc.getArray(dataName);

    // Function to update state with Yjs data
    const updateData = () => {
      setData(
        (dataType === "text"
          ? (yData as Text).toString()
          : (yData as YArray<any>).toArray()) as unknown as T
      );
    };

    // Initial data update and observation
    updateData();
    yData.observe(updateData);

    // Cleanup function to remove observer
    return () => {
      yData.unobserve(updateData);
    };
  }, [provider, dataName, dataType]);

  /**
   * Function to update the Yjs data
   * @param {T} newData - The new data to set
   */
  const updateData = (newData: T) => {
    if (!provider) return;

    // Get the Yjs data instance
    const yData: YDataType =
      dataType === "text"
        ? provider.doc.getText(dataName)
        : provider.doc.getArray(dataName);
    if (dataType === "text") {
      const yText = yData as Text;
      yText.delete(0, yText.length);
      yText.insert(0, newData as unknown as string);
    } else {
      const yArray = yData as YArray<any>;
      yArray.delete(0, yArray.length);
      yArray.push(Array.isArray(newData) ? newData : [newData]);
    }
  };

  return [data, updateData] as const;
}

/**
 * Custom hook to manage real-time synchronization of Yjs text data
 * @param {string} textName - The name of the text data in the Yjs document
 * @returns {[string, (newText: string) => void]} The current text data and a function to update it
 */
export function useRealTimeText(textName: string) {
  return useRealTime<string>(textName, "text");
}

/**
 * Custom hook to manage real-time synchronization of Yjs array data
 * @template T
 * @param {string} arrayName - The name of the array data in the Yjs document
 * @returns {[T, (newArray: T) => void]} The current array data and a function to update it
 */
export function useRealTimeArray<T>(arrayName: string) {
  return useRealTime<T>(arrayName, "array");
}
