import { useState } from "react";
import { createRoot } from "react-dom/client";
import { RoomProvider } from "../../client";
import { Card } from "./components/Card";
import "./styles.css";

function App() {
  const [rooms, setRooms] = useState(["test1", "test2", "test3", "test4", "test5", "test6", "test7", "test8"]);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-y-2 gap-x-6 items-start p-8">
        {
          rooms.map((room: string) => (
            <RoomProvider key={room} roomId={room}>
              <Card />
            </RoomProvider>
          ))
        }
    </div>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
